<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <!-- Row for add button -->
        <v-row
          flex
          align="center"
          justify="space-between"
          class="pa-2"
        >
          <v-col></v-col>
          <v-col class="d-flex align-center justify-end flex-grow-0">
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase ms-2"
            >
              Per productie
            </v-btn>
            <v-btn
              color="primary"
              outlined
              rounded
              depressed
              class="font-weight-bold text-lowercase ms-2"
              :to="{ name: 'SalesMonitorPerformances' }"
            >
              Per voorstelling
            </v-btn>
          </v-col>
        </v-row>

        <!-- Page info first row -->
        <v-row
          flex
          justify="center"
          align="center"
          class="pb-2"
          no-gutters
        >
          <!-- Breadcrumbs and help -->
          <v-col class="d-flex align-center py-2">
            <v-breadcrumbs
              :items="breadcrumbs"
              class="pa-0 pl-4"
            >
              <template v-slot:divider>
                <v-icon class="primary--text">mdi-chevron-right</v-icon>
              </template>
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item
                  :to="{ path: item.href }"
                  :exact="true"
                  :disabled="item.disabled"
                >
                  <span
                    class="primary--text"
                    :class="{ 'font-weight-bold': item.bold }"
                    >{{ item.text }}</span
                  >
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>

            <help
              bg-color="#fff"
              help-id="75"
            ></help>
          </v-col>

          <!-- Filter -->
          <v-col class="py-0 d-flex align-center justify-end">
            <div class="d-none d-sm-flex">
              <filter-period
                :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                selectedDefault="thisSeason"
                @changePeriod="filterPeriod = $event"
              ></filter-period>
              <v-autocomplete
                v-if="user.organisationTypeId !== 1"
                v-model="tableSalesMonitorFilter.agencyId"
                :items="agencies"
                item-text="text"
                label="impresariaat selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                v-if="user.organisationTypeId !== 3"
                v-model="tableSalesMonitorFilter.producerId"
                :items="producers"
                label="producent selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                v-if="user.organisationTypeId !== 2"
                v-model="tableSalesMonitorFilter.theaterId"
                :items="theaters"
                label="selecteer een theater"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-text-field
                v-model="tableSalesMonitorFilter.productionName"
                label="productie zoeken"
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-text-field>
              <v-autocomplete
                v-model="tableSalesMonitorFilter.genreId"
                :items="genres"
                label="genre selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
              <v-autocomplete
                v-if="user.organisationTypeId == 2"
                v-model="tableSalesMonitorFilter.areaId"
                :items="areaNames"
                item-text="text"
                label="zaal selecteren"
                clearable
                hide-details
                dense
                outlined
                background-color="#fff"
                class="ma-1 input-sm"
              ></v-autocomplete>
            </div>

            <!-- Filter dialog -->
            <div class="d-sm-none">
              <v-dialog
                v-model="tableSalesMonitorFilterDialog"
                width="500"
                fullscreen
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="font-weight-bold text-lowercase"
                    color="success"
                    rounded
                    dark
                    v-on="on"
                    >filter overzicht
                    <v-icon
                      small
                      right
                      >mdi-filter</v-icon
                    ></v-btn
                  >
                </template>

                <v-card>
                  <v-card-title class="headline dark"><h5 class="primary--text">filter overzicht</h5></v-card-title>

                  <v-card-text>
                    <filter-period
                      :includeOptions="['thisWeek', 'thisSeason', 'nextSeason', 'prevSeason']"
                      selectedDefault="thisSeason"
                      @changePeriod="filterPeriod = $event"
                    ></filter-period>
                    <v-autocomplete
                      v-if="user.organisationTypeId !== 1"
                      v-model="tableSalesMonitorFilter.agencyId"
                      :items="agencies"
                      item-text="text"
                      label="impresariaat selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-if="user.organisationTypeId !== 3"
                      v-model="tableSalesMonitorFilter.producerId"
                      :items="producers"
                      label="producent selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-if="user.organisationTypeId !== 2"
                      v-model="tableSalesMonitorFilter.theaterId"
                      :items="theaters"
                      label="selecteer een theater"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                    <v-text-field
                      v-model="tableSalesMonitorFilter.productionName"
                      label="productie zoeken"
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-text-field>
                    <v-autocomplete
                      v-model="tableSalesMonitorFilter.genreId"
                      :items="genres"
                      label="genre selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                    <v-autocomplete
                      v-if="user.organisationTypeId == 2"
                      v-model="tableSalesMonitorFilter.areaId"
                      :items="areaNames"
                      item-text="text"
                      label="zaal selecteren"
                      clearable
                      hide-details
                      dense
                      outlined
                      background-color="#fff"
                      class="ma-1 input-sm"
                    ></v-autocomplete>
                  </v-card-text>

                  <v-card-actions class="justify-center flex-column">
                    <v-btn
                      class="font-weight-bold text-lowercase mb-5"
                      color="success"
                      depressed
                      @click="tableSalesMonitorFilterDialog = false"
                      >filter overzicht
                      <v-icon
                        small
                        right
                        >mdi-filter</v-icon
                      ></v-btn
                    >
                    <v-btn
                      class="font-weight-bold text-lowercase"
                      color="error"
                      outlined
                      dark
                      @click="clearFilterProductions()"
                      >reset filter
                      <v-icon
                        small
                        right
                        >mdi-close</v-icon
                      ></v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-col>
        </v-row>

        <!-- Table -->
        <v-data-table
          :headers="tableSalesMonitorHeadersFiltered"
          :items="tableSalesMonitorFiltered"
          :mobile-breakpoint="0"
          :loading="tableSalesMonitorLoading"
          :page.sync="tableSalesMonitorPage"
          :items-per-page="tableSalesMonitorItemsPerPage"
          :item-class="addClassToTableRow"
          hide-default-footer
          @page-count="tableSalesMonitorPageCount = $event"
          @click:row="goToDetail"
          class="table-action row-is-clickable"
          fixed-header
          :height="tableSalesMonitorFiltered.length > 10 ? '55vh' : ''"
        >
          <template v-slot:[`item.title`]="{ item }">
            <span>{{ item.title }}</span>
            <span class="tooltip">Einde tour</span>
          </template>

          <template v-slot:[`item.localOccupancyRate`]="{ item }">
            <td class="tooltip-container">
              <span class="tooltip"
                ><table>
                  <tbody>
                    <tr>
                      <th scope="row">capaciteit</th>
                      <td>{{ item.totalCapacity }}</td>
                    </tr>
                    <tr>
                      <th scope="row">%bezet</th>
                      <td>
                        {{
                          item.localOccupancyRate === 0
                            ? item.localOccupancyRate + '%'
                            : getPercentageView(item.localOccupancyRate)
                        }}
                      </td>
                    </tr>
                    <tr v-if="user.organisationTypeId == 2">
                      <th scope="row">%bezet landelijk</th>
                      <td>
                        {{
                          item.nationalOccupancyRate === 0
                            ? item.nationalOccupancyRate + '%'
                            : getPercentageView(item.nationalOccupancyRate)
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <v-progress-linear
                class="custom_progress"
                :background-color="'#e1e7f2'"
                :height="20"
                :width="500"
                :rounded="true"
                :value="item.localOccupancyRate"
                :color="'#0abfb8'"
              >
                <div
                  class="target"
                  :style="{ left: item.nationalOccupancyRate + '%' }"
                  v-if="item.nationalOccupancyRate > 0 && user.organisationTypeId == 2"
                ></div>
              </v-progress-linear>
            </td>
          </template>

          <template v-slot:[`item.totalTickets`]="{ item }">
            <td
              class="tooltip-container textual"
              v-if="item.averageTotalTickets != null"
            >
              <span class="tooltip"
                ><table>
                  <tbody>
                    <tr>
                      <th scope="row">landelijk gemiddelde</th>
                      <td>
                        {{ formatThousandsWithDot(item.averageTotalTickets) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </td>
            <div v-else>
              <span>{{ formatThousandsWithDot(item.totalTickets) }}</span>
            </div>
          </template>

          <template v-slot:[`item.totalRevenueInVat`]="{ item }">
            <span>{{ getEuroSign(item.totalRevenueInVat) }}</span>
          </template>

          <template v-slot:[`item.reservedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.reservedTickets) }}</span>
          </template>

          <template v-slot:[`item.blockedTickets`]="{ item }">
            <span>{{ formatThousandsWithDot(item.blockedTickets) }}</span>
          </template>

          <template v-slot:[`item.localPerformances`]="{ item }">
            <span>{{ formatThousandsWithDot(item.localPerformances) }}</span>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              fab
              x-small
              depressed
              color="warning"
              class="ml-1"
              :to="{
                name: 'SalesMonitorProductionsDetail',
                params: { productionNumber: item.number },
              }"
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>

          <template
            slot="body.append"
            v-if="tableSalesMonitorFilterActive"
          >
            <tr class="table-selection-total">
              <th class="title">selectie</th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorSelected.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.reservedTickets) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.blockedTickets) }}
              </th>
              <th class=""></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorSelected.localPerformances) }}
              </th>
              <th
                class=""
                v-for="header in tableSalesMonitorHeadersFiltered.slice(7)"
                :key="header.value"
              ></th>
            </tr>
          </template>

          <template
            slot="body.append"
            v-if="!tableSalesMonitorLoading"
          >
            <tr class="table-total">
              <th class="title">totaal</th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.totalTickets) }}
              </th>
              <th class="text">
                {{ getEuroSign(this.tableSalesMonitorTotal.totalRevenueInVat) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.reservedTickets) }}
              </th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.blockedTickets) }}
              </th>
              <th class=""></th>
              <th class="text">
                {{ formatThousandsWithDot(this.tableSalesMonitorTotal.performancesCount) }}
              </th>
              <th
                class=""
                v-for="header in tableSalesMonitorHeadersFiltered.slice(7)"
                :key="header.value"
              ></th>
            </tr>
          </template>

          <v-data-footer></v-data-footer>
        </v-data-table>

        <!-- Table settings -->
        <v-row
          class="text-center pt-2"
          align="center"
        >
          <!-- Table totaal -->
          <v-col cols="12">
            <p class="font-weight-medium text-left caption primary--text ma-0 pa-0">
              totaal: {{ this.tableSalesMonitorFiltered.length }}
            </p>
          </v-col>

          <!-- Table buttons items per page -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex align-center justify-center justify-sm-start"
          >
            <v-btn
              v-if="tableSalesMonitorFiltered.length > 25"
              @click="tableSalesMonitorItemsPerPage = 25"
              :color="tableSalesMonitorItemsPerPage == 25 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >25</v-btn
            >
            <v-btn
              v-if="tableSalesMonitorFiltered.length > 25"
              @click="tableSalesMonitorItemsPerPage = 50"
              :color="tableSalesMonitorItemsPerPage == 50 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >50</v-btn
            >
            <v-btn
              v-if="tableSalesMonitorFiltered.length > 50"
              @click="tableSalesMonitorItemsPerPage = 100"
              :color="tableSalesMonitorItemsPerPage == 100 ? 'primary' : '#fff'"
              height="45"
              width="45"
              elevation="0"
              x-small
              class="pa-0 mr-1 font-weight-bold body-2"
              >100</v-btn
            >
          </v-col>

          <!-- Table pagination -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-pagination
              v-if="tableSalesMonitorPageCount > 1"
              v-model="tableSalesMonitorPage"
              :length="tableSalesMonitorPageCount"
              :total-visible="7"
              :elevation="0"
            ></v-pagination>
          </v-col>

          <!-- Table download -->
          <v-col
            cols="12"
            sm="4"
            class="d-flex justify-center justify-sm-end"
          >
            <v-btn
              color="primary"
              rounded
              depressed
              class="font-weight-bold text-lowercase"
            >
              <vue-excel-xlsx
                class="v-btn font-weight-bold text-lowercase"
                :data="tableSalesMonitorFiltered"
                :columns="downloadExportColumns"
                :filename="'dip-verkoop-en-publiek-producties-export'"
                :sheetname="'Verkoopmonitor producties'"
                >exporteer selectie <v-icon right>mdi-file-move</v-icon></vue-excel-xlsx
              >
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import ApiService from '../../services/api.service';
import { TokenService } from '../../services/storage.service';
import Help from '../../components/Help.vue';
import functions from '../../services/functions.service';
import FilterPeriod from '../../components/FilterPeriod';

export default {
  name: 'SalesMonitorProductions',
  components: {
    Help,
    FilterPeriod,
  },
  data() {
    return {
      rights: {
        edit: false,
        approve: false,
      },
      breadcrumbs: [
        {
          text: 'verkoopmonitor',
          disabled: false,
          href: '',
          bold: true,
        },
      ],
      filterPeriod: {
        from: '',
        to: '',
      },
      productions: [],
      tableSalesMonitorSelected: {
        localPerformances: 0,
        sold: 0,
        reserved: 0,
        blocked: 0,
        totalRevenueInVat: 0,
        myShare: 0,
      },
      tableSalesMonitorTotal: {
        performancesCount: 0,
        sold: 0,
        reserved: 0,
        blocked: 0,
        totalRevenueInVat: 0,
        myShare: 0,
      },
      tableSalesMonitorFiltered: [],
      selectAgencyId: '',
      theaters: [],
      agencies: [],
      producers: [],
      genres: [],
      areaNames: [],
      tableSalesMonitorLoading: true,
      tableSalesMonitorHeaders: [
        { text: 'naam', value: 'title' },
        { text: 'verkocht', value: 'totalTickets' },
        { text: 'recette', value: 'totalRevenueInVat' },
        { text: 'gereserv.', value: 'reservedTickets' },
        { text: 'geblok.', value: 'blockedTickets' },
        {
          text: '% bezet',
          value: 'localOccupancyRate',
          sortable: false,
          width: '125px',
        },
        { text: 'aantal vrst.', value: 'localPerformances' },
        { text: 'producent', value: 'producerName' },
        { text: 'co-productie', value: 'isCoProduction', showFor: [1, 3] },
        { text: 'impresariaat', value: 'agencyName', showFor: [2, 3] },
        { text: '', value: 'action', sortable: false, align: 'end' },
      ],
      tableSalesMonitorFilterActive: false,
      tableSalesMonitorFilter: {
        productionName: '',
        agencyId: '',
        theaterId: '',
        producerId: '',
        genreId: '',
        areaId: '',
      },
      tableSalesMonitorPage: 1,
      tableSalesMonitorPageCount: 0,
      tableSalesMonitorItemsPerPage: 25,
      tableSalesMonitorFilterDialog: false,
      downloadExportColumns: [
        { label: 'nummer', field: 'number' },
        { label: 'naam', field: 'title' },
        { label: 'aantal vrijkaarten', field: 'freeTickets' },
        { label: 'verkocht normaal', field: 'normalTickets' },
        {
          label: 'verkocht landelijk gemiddelde',
          field: 'averageTotalTickets',
        },
        { label: 'recette normaal', field: 'normalRevenueInVat' },
        { label: 'verkocht korting', field: 'discountTickets' },
        { label: 'recette korting', field: 'discountRevenueInVat' },
        { label: 'verkocht overig', field: 'otherTickets' },
        { label: 'recette overig', field: 'otherRevenueInVat' },
        { label: 'verkocht totaal', field: 'totalTickets' },
        { label: 'recette totaal', field: 'totalRevenueInVat' },
        { label: 'gereserveerd', field: 'reservedTickets' },
        { label: 'recette reserveringen', field: 'reservedRevenueInVat' },
        { label: 'geblokkeerd', field: 'blockedTickets' },
        { label: 'capaciteit', field: 'totalCapacity' },
        { label: '%bezet', field: 'localOccupancyRate' },
        { label: '%bezet landelijk', field: 'nationalOccupancyRate' },
        { label: 'voorstellingen hier', field: 'localPerformances' },
        { label: 'voorstellingen landelijk', field: 'nationalPerformances' },
        { label: 'producent', field: 'producerName' },
        { label: 'co-producent', field: 'coProducers' },
        { label: 'muzikale begeleiding', field: 'accompanyingProducers' },
        { label: 'impresariaat', field: 'agencyName' },
      ],
    };
  },
  mounted() {
    this.rights = this.getComponentRights('SalesMonitor');
    // Filter
    this.checkFilter({
      viewName: this.$options.name,
      filterObject: this.tableSalesMonitorFilter,
    });
    this.tableSalesMonitorFilter = this.getFilter(this.$options.name);
  },
  methods: {
    ...mapActions('storage', ['checkFilter', 'updateFilter']),
    ...mapMutations('storage', ['getStoredSalesMonitorProductions', 'getStoredSalesMonitorProductionsFilterPeriod']),

    goToDetail(row) {
      this.$router.push({
        name: 'SalesMonitorProductionsDetail',
        params: { productionNumber: row.number },
      });
    },
    formatThousandsWithDot: function (value) {
      return new Intl.NumberFormat('nl-NL').format(value);
    },
    getEuroSign: function (value) {
      return functions.getEuroSignView(value);
    },
    getPercentageView: function (value) {
      return functions.getPercentageViewWithoutDecimals(value);
    },
    calculateArraySum: function (items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    },
    getProductions: function () {
      this.productions = [];
      this.tableSalesMonitorFiltered = [];
      this.tableSalesMonitorLoading = true;

      if (
        this.$store.state.storage.storedSalesMonitorProductions != undefined &&
        this.$store.state.storage.storedSalesMonitorProductions.length > 0 &&
        this.filterPeriod.from.toString() == this.$store.state.storage.storedFilterPeriod.dateFrom.toString() &&
        this.filterPeriod.to.toString() == this.$store.state.storage.storedFilterPeriod.dateTo.toString()
      ) {
        this.productionsDataHandler(this.$store.state.storage.storedSalesMonitorProductions, true);
        this.filterProductions();
        this.tableSalesMonitorLoading = false;
      } else {
        ApiService.get('/SalesMonitor/GetProductions', {
          startDate: functions.jsDateToStringDate(this.filterPeriod.from),
          endDate: functions.jsDateToStringDate(this.filterPeriod.to),
        })
          .then((response) => {
            TokenService.saveToken(response.data.token);
            ApiService.setHeader();
            this.productions = response.data.result;

            this.productionsDataHandler(this.productions, false);

            this.filterProductions();
            this.getStoredSalesMonitorProductions(this.productions);
            this.getStoredSalesMonitorProductionsFilterPeriod(this.filterPeriod);

            this.tableSalesMonitorLoading = false;
          })
          .catch((error) => {
            //eslint-disable-next-line
            console.log(error);
            this.errored = true;
          });
      }
    },
    productionsDataHandler: function (response, cachedProductions) {
      var vm = this;
      this.productions = response;

      for (var i = 0; i < this.productions.length; i++) {
        if (!cachedProductions) {
          if (this.productions[i].isCoProduction == true) {
            this.productions[i].isCoProduction = 'Ja';
          } else {
            this.productions[i].isCoProduction = 'Nee';
          }
        }
        vm.producers.push({
          value: this.productions[i].producerId,
          text: this.productions[i].producerName,
        });

        for (var j = 0; j < this.productions[i].theaters.length; j++) {
          vm.theaters.push({
            value: this.productions[i].theaters[j].id,
            text: this.productions[i].theaters[j].name,
          });
        }

        if (this.productions[i].agencyId != 0 && this.productions[i].agencyName != null) {
          vm.agencies.push({
            value: this.productions[i].agencyId,
            text: this.productions[i].agencyName,
          });
        }
        vm.genres.push({
          value: this.productions[i].genreId,
          text: this.productions[i].genreName,
        });

        for (var k = 0; k < this.productions[i].areas.length; k++) {
          vm.areaNames.push({
            value: this.productions[i].areas[k].id,
            text: this.productions[i].areas[k].name,
          });
        }
      }

      // Set genre filter
      const genreFromQuery = this.$route.query.genre;
      if (genreFromQuery) {
        const firstMatchingGenreIdx = vm.genres.findIndex((genre) => genre.text === genreFromQuery);
        if (firstMatchingGenreIdx !== -1) {
          const genreId = vm.genres[firstMatchingGenreIdx].value;
          this.tableSalesMonitorFilter.genreId = genreId;

          // Filter
          this.updateFilter({
            viewName: this.$options.name,
            filterObject: this.tableSalesMonitorFilter,
          });
        }
      }

      this.tableSalesMonitorTotal.performancesCount = this.calculateArraySum(this.productions, 'localPerformances');
      this.tableSalesMonitorTotal.totalTickets = this.calculateArraySum(this.productions, 'totalTickets');
      this.tableSalesMonitorTotal.reservedTickets = this.calculateArraySum(this.productions, 'reservedTickets');
      this.tableSalesMonitorTotal.blockedTickets = this.calculateArraySum(this.productions, 'blockedTickets');
      this.tableSalesMonitorTotal.totalRevenueInVat = this.calculateArraySum(this.productions, 'totalRevenueInVat');

      this.tableSalesMonitorFiltered = this.productions;

      // Paging
      this.checkFilter({
        viewName: this.$options.name + '-paging',
        filterObject: this.tableSalesMonitorPage,
      });
      this.tableSalesMonitorPage = this.getFilter(this.$options.name + '-paging');
    },
    filterProductions: function () {
      const vm = this;

      if (
        this.tableSalesMonitorFilter.productionName == '' &&
        (this.tableSalesMonitorFilter.agencyId == '' || this.tableSalesMonitorFilter.agencyId == undefined) &&
        (this.tableSalesMonitorFilter.theaterId == '' || this.tableSalesMonitorFilter.theaterId == undefined) &&
        (this.tableSalesMonitorFilter.producerId == '' || this.tableSalesMonitorFilter.producerId == undefined) &&
        (this.tableSalesMonitorFilter.genreId == '' || this.tableSalesMonitorFilter.genreId == undefined) &&
        (this.tableSalesMonitorFilter.areaId == '' || this.tableSalesMonitorFilter.areaId == undefined)
      ) {
        this.tableSalesMonitorFilterActive = false;
      } else {
        this.tableSalesMonitorFilterActive = true;
      }

      this.tableSalesMonitorFiltered = this.productions.filter(function (production) {
        return (
          cleanWords(production.title).indexOf(cleanWords(vm.tableSalesMonitorFilter.productionName)) !== -1 &&
          (production.agencyId == vm.tableSalesMonitorFilter.agencyId ||
            vm.tableSalesMonitorFilter.agencyId == '' ||
            vm.tableSalesMonitorFilter.agencyId == undefined) &&
          (production.theaters.filter((e) => e.id === vm.tableSalesMonitorFilter.theaterId).length > 0 ||
            vm.tableSalesMonitorFilter.theaterId == '' ||
            vm.tableSalesMonitorFilter.theaterId == undefined) &&
          (production.producerId == vm.tableSalesMonitorFilter.producerId ||
            vm.tableSalesMonitorFilter.producerId == '' ||
            vm.tableSalesMonitorFilter.producerId == undefined) &&
          (production.genreId == vm.tableSalesMonitorFilter.genreId ||
            vm.tableSalesMonitorFilter.genreId == '' ||
            vm.tableSalesMonitorFilter.genreId == undefined) &&
          (production.areas.filter((p) => p.id === vm.tableSalesMonitorFilter.areaId).length > 0 ||
            vm.tableSalesMonitorFilter.areaId == '' ||
            vm.tableSalesMonitorFilter.areaId == undefined)
        );
      });

      this.tableSalesMonitorSelected.localPerformances = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'localPerformances'
      );
      this.tableSalesMonitorSelected.totalTickets = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'totalTickets'
      );
      this.tableSalesMonitorSelected.reservedTickets = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'reservedTickets'
      );
      this.tableSalesMonitorSelected.blockedTickets = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'blockedTickets'
      );
      this.tableSalesMonitorSelected.totalRevenueInVat = this.calculateArraySum(
        this.tableSalesMonitorFiltered,
        'totalRevenueInVat'
      );

      function cleanWords(word) {
        if (word == undefined) {
          return '';
        }
        return word
          .normalize('NFD')
          .replace(/[^a-zA-Z 0-9]/g, '')
          .toLowerCase();
      }
    },
    clearFilterProductions: function () {
      this.tableSalesMonitorFilter.productionName = '';
      this.tableSalesMonitorFilter.agencyId = '';
      this.tableSalesMonitorFilter.theaterId = '';
      this.tableSalesMonitorFilter.producerId = '';
      this.tableSalesMonitorFilter.genreId = '';
      this.tableSalesMonitorFilter.areaId = '';
      this.tableSalesMonitorFilterActive = false;
      this.tableSalesMonitorFilterDialog = false;
    },
    addClassToTableRow(item) {
      if (item.hasEnded) {
        return 'has-ended';
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['getComponentRights']),
    ...mapState('auth', ['user']),
    ...mapGetters('storage', ['getFilter']),

    tableSalesMonitorHeadersFiltered() {
      return this.tableSalesMonitorHeaders.filter(
        (h) => !h.showFor || h.showFor.includes(this.user.organisationTypeId)
      );
    },
  },
  beforeDestroy() {
    // Filter
    this.updateFilter({
      viewName: this.$options.name,
      filterObject: this.tableSalesMonitorFilter,
    });
    // Paging
    this.updateFilter({
      viewName: this.$options.name + '-paging',
      filterObject: this.tableSalesMonitorPage,
    });
  },
  watch: {
    tableSalesMonitorFilter: {
      handler: 'filterProductions',
      deep: true,
    },
    filterPeriod: function () {
      this.getProductions();
    },
  },
};
</script>

<style lang="scss">
.target {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  max-width: 2px;
  background-color: #021e72;
}

td.text-start {
  position: relative;
}

tr.has-ended > td:first-of-type::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  background: var(--v-secondary-base);
}

tr.has-ended td.text-start > span + span.tooltip {
  position: absolute;
  left: 0.5rem;
}

tr.has-ended td.text-start:hover > span + span.tooltip {
  display: block;
}
</style>
